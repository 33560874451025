import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import HomeSectionInfinite from "../../components/HomeSectionInfinite"
import Breadcrumbs from "../../components/Breadcrumbs"
import SEO from "../../components/SEO"

const TopicTemplate = ({ data }) => {
  const topic = data.topicsPt
  const cards = data.allCardsPt.edges.sort(
    (a, b) => -a.node.createdAt.localeCompare(b.node.createdAt)
  )

  const metaDescription =
    topic.description ||
    `Acesse os melhores conteúdos sobre espiritualidade, história e
  sabedorias islâmicas com nossos tópicos`

  const renderDescription = () => {
    if (topic.description) {
      return <p>{topic.description}</p>
    }

    return (
      <p>
        Acesse os melhores conteúdos sobre espiritualidade, história e
        sabedorias islâmicas com nossas coleções
      </p>
    )
  }

  return (
    <Layout>
      <SEO
        title={topic.name}
        description={metaDescription}
        card={false}
        image={topic.imageUrl}
        canonical={`${process.env.GATSBY_HOST_URL}/pt/topics/${topic.slug}`}
      />

      <div className="topics">
        <div className="main-content" style={{ marginTop: 117 }}>
          <Breadcrumbs
            currentPage={topic.name}
            secondaryLink={"/pt/topics"}
            secondaryLinkName="Tópicos"
            style={{ marginTop: 0, marginBottom: 30, background: topic.color }}
          />

          <div className="topics-header">
            <h1 style={{ color: topic.color }}>{topic.name}</h1>
            {renderDescription()}
          </div>

          <HomeSectionInfinite
            name=""
            cards={cards}
            style={{ marginTop: 50, marginBottom: 50 }}
            customClass="home-section-card-list"
          />
        </div>
      </div>
    </Layout>
  )
}

export default TopicTemplate

export const query = graphql`
  query($id: String!, $slug: String!) {
    topicsPt(id: { eq: $id }) {
      name
      color
      slug
      description
      imageUrl
      createdAt
    }

    allCardsPt(filter: { topic: { slug: { eq: $slug } } }) {
      edges {
        node {
          name
          slug
          imageUrl
          type
          createdAt
          topic {
            name
            color
          }
          tags {
            name
          }
          meta {
            content
            excerpt
            author
            listFields {
              title
              content
            }
          }
        }
      }
    }
  }
`
