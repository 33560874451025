import React from "react"
import { Link } from "gatsby"

import "./Breadcrumbs.css"

const Breadcrumbs = ({
  currentPage,
  secondaryLink,
  secondaryLinkName,
  search,
  style,
}) => {
  if (secondaryLink) {
    return (
      <div className="breadcrumbs" style={style}>
        <Link to="/pt">Início</Link> >{" "}
        <Link to={secondaryLink}>{secondaryLinkName}</Link> >{" "}
        <span>{currentPage}</span>
      </div>
    )
  } else if (search) {
    return (
      <div className="breadcrumbs" style={style}>
        <Link to="/pt">Início</Link> > <span>{currentPage}</span> >{" "}
        <span style={{ color: "#8a8a8a" }}>{search}</span>
      </div>
    )
  }

  return (
    <div className="breadcrumbs" style={style}>
      <Link to="/pt">Início</Link> > <span>{currentPage}</span>
    </div>
  )
}

export default Breadcrumbs
