import React, { useRef, useState, useLayoutEffect } from "react"

import "./HomeSection.css"

import LoadingIcon from "../images/icons/Loading"
import Card from "./Card"
import CollectionCard from "./CollectionCard"
import SearchCard from "./SearchCard"

const HomeSectionInfinite = ({
  name,
  cards,
  style,
  isCollection = false,
  isSearch = false,
  customClass,
}) => {
  const infiniteScrollEl = useRef(null)

  const [infiniteLoading, setInfiniteLoading] = useState(false)
  const [infiniteLastItem, setInfiniteLastItem] = useState(false)

  const [currentPaginationValue, setCurrentPaginationValue] = useState(12)

  const [localCards, setLocalCards] = useState(cards.slice(0, 12))

  const handleLoadItems = async () => {
    if (cards.length === localCards.length) {
      setInfiniteLoading(false)
      return false
    }

    setInfiniteLoading(true)

    setCurrentPaginationValue(currentPaginationValue + 12)

    setLocalCards(cards.slice(0, currentPaginationValue))

    setTimeout(() => {
      setInfiniteLoading(false)
    }, 500)
  }

  useLayoutEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      const ratio = entries[0].intersectionRatio

      if (ratio && ratio > 0 && !infiniteLoading && !infiniteLastItem) {
        handleLoadItems()
      }
    })

    if (infiniteScrollEl.current) {
      intersectionObserver.observe(infiniteScrollEl.current)
    }

    return () => intersectionObserver.disconnect()
  })

  const renderCard = card => {
    if (isCollection) {
      return <CollectionCard card={card} />
    } else if (isSearch) {
      return <SearchCard card={card} />
    }
    return <Card card={card} />
  }

  return (
    <div
      className={`home-section ${customClass ? customClass : ""}`}
      style={style}
    >
      <div className="home-section-header">
        <span>{name}</span>
      </div>

      <div className="home-section-carousel">
        <div className="cards">{localCards.map(card => renderCard(card))}</div>

        <div className="infinite-loading-el" ref={infiniteScrollEl}>
          {infiniteLoading && (
            <div className="loading-spinner">
              <LoadingIcon />
            </div>
          )}
          {infiniteLastItem && <hr />}
        </div>
      </div>
    </div>
  )
}

export default HomeSectionInfinite
