import React from "react"
import { Link } from "gatsby"
import chroma from "chroma-js"

import "./Card.css"

const SearchCard = ({ card }) => {
  const renderTitle = title => {
    if (title.length > 54) {
      return `${title.substring(0, 54)}...`
    }
    return title
  }

  if (card.type === "quote") {
    const bgColor = chroma(card.topicColor).brighten(2)
    const titleColor = chroma(card.topicColor).darken()

    return (
      <Link
        to={`/pt/cards/${card.slug}`}
        className="card-quote"
        style={{ backgroundColor: bgColor }}
      >
        <span className="card-quote-topic" style={{ color: card.topicColor }}>
          {card.topicName}
        </span>

        <h3 style={{ color: titleColor }}>{card.name}</h3>

        <div className="card-quote-info">
          <div
            className="card-quote-img"
            style={{
              backgroundImage: `url(${card.imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <span style={{ color: card.topicColor }}>{card.authorName}</span>
        </div>

        {/* <div className="card-quote-tags">
          {card.tags.slice(0, 2).map(tag => (
            <span>{tag.name}</span>
          ))}
        </div> */}
      </Link>
    )
  }

  return (
    <Link to={`/pt/cards/${card.slug}`} className="card">
      <div
        className="card-img"
        style={{
          backgroundImage: `url(${card.imageUrl})`,
          backgroundSize: "cover",
        }}
      ></div>
      <div className="card-info">
        <span style={{ color: card.topicColor }}>{card.topicName}</span>
        <h3 style={{ marginTop: 10 }}>{renderTitle(card.name)}</h3>

        {/* <div className="card-tags">
          {card.tags.slice(0, 2).map(tag => (
            <span>{tag.name}</span>
          ))}
        </div> */}
      </div>
    </Link>
  )
}

export default SearchCard
